import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import CustomSelect from './CustomSelect'
import Down from '../assets/arrowDown.svg'
import UserContext from 'contexts/UserContext'
import FrIcon from '../assets/flag-fr.png'
import GrcIcon from '../assets/grc.svg'
import ItaIcon from '../assets/ita.svg'
import NldIcon from '../assets/nld.svg'
import PrtIcon from '../assets/prt.svg'
import LuxIcon from '../assets/lux.svg'
import GerIcon from '../assets/ger.svg'
import DeuIcon from '../assets/deu.svg'
import AutIcon from '../assets/aut.svg'
import BelIcon from '../assets/bel.svg'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

interface Props {
  euroValue: number
  handleEuroChange: any
  taux: number
  error: boolean
  dinarValue: any
  com:any
  number: number
  setEuroValue: (value: number) => void
}
const InputCalculateMontantEnvoyer: React.FC<Props> = ({ euroValue, handleEuroChange, taux, error, dinarValue, com, number, setEuroValue}) => {
  const { user } = useContext(UserContext)

  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const optionsPaysSelected: { icon: any; code: string; frenshName: string; prefix: string }[] = [
    { icon: FrIcon, frenshName: 'France', code: 'FRA', prefix: '+33' },
    { icon: DeuIcon, frenshName: 'Espagne', code: 'ESP', prefix: '+34' },
    { icon: AutIcon, frenshName: 'Autriche', code: 'AUT', prefix: '+43' },
    { icon: BelIcon, frenshName: 'Belgique', code: 'BEL', prefix: '+39' },
    { icon: GrcIcon, frenshName: 'Grèce', code: 'GRC', prefix: '+32' },
    { icon: ItaIcon, frenshName: 'Italie', code: 'ITA', prefix: '+30' },
    { icon: NldIcon, frenshName: 'Pays Bas', code: 'NLD', prefix: '+31' },
    { icon: PrtIcon, frenshName: 'Portugal', code: 'PRT', prefix: '+351' },
    { icon: LuxIcon, frenshName: 'Luxembourg', code: 'LUX', prefix: '+352' },
    { icon: GerIcon, frenshName: 'Allemagne', code: 'DEU', prefix: '+49' },
  ]
  
  useEffect(() => {
    if (euroValue) {
      const newAmount = { ...TransferData, euroValue }
      setTransferData(newAmount)
    }
  }, [euroValue])

  useEffect(() => {
    if (TransferData) {
      const euroValue = TransferData.euroValue
      if (typeof euroValue === 'number') {
        setEuroValue(euroValue)
      } 
    }
  }, [])


  return (
    <div className="flex items-center gap-[12px] w-full sm:w-auto 2sm:w-[340px]">
      <div className="flex flex-col w-full">
        <div className={`flex flex-col w-full h-[100px]`}>
          <div
            className={classNames(
              'text-[#050708] w-full sm:w-[240px] flex-none mb-1 font-semibold text-[17px] sm:text-[14px]',
            )}
          >
            Montant à envoyer <span className="text-[#FF0000]">*</span> 
          </div>
          <input
            type="text"
            placeholder="valeur en euro"
            value={euroValue}
            onChange={(e:any)=>{handleEuroChange(e.target.value)}}
            className="bg-gray-100  w-[418px] sm:w-full xl:w-auto rounded-xl h-[50px] pl-3 py-3 text-[#1D3A70] placeholder:text-[#424242]"
          />
          {error && (
            <div className="text-[#FF0000] text-[14px] sm:text-[11px]">
            {number === 0 && (euroValue < 15 || euroValue > 3000) && (
              'Le montant à envoyer par Transfert doit être entre 15 et 3000 €'
            )}
            {number === 1 && (euroValue < 15 || euroValue > 5000) && (
              'Le montant à envoyer par Transfert doit être entre 15 et 5000 €'
            )}
          </div>
          )}
        </div>
        <div className={`pt-[2rem] text-[#5754E5] text-center text-[15px] sm:text-[12px] sm:ml-10 font-semibold`}>
          Votre Taux de Change : {' '} {
            euroValue < 15
            ? '-'
            : number === 1 && euroValue > 5000 || number === 0 && euroValue > 3000
            ? '-'
            : com
          }
          {' '} + {' '}
          { euroValue < 15
            ? '-'
            : number === 1 && euroValue > 5000 || number === 0 && euroValue > 3000
            ? '-'
            : `${taux} €  commission`}
        </div>
      </div>

      <div className={`flex flex-col mt-1 h-[150px] sm:h-[160px]`}>
        <div className={classNames('text-[#050708] font-semibold text-[17px] sm:text-[14px] sm:w-[99px] w-[106px]')}>
          Pays
        </div>

        {Array.from(
          new Set(
            optionsPaysSelected.map(
              (item: any, index: number) =>
                item.code === user?.user?.address?.country?.code && (
                  <div
                    className={`rounded-xl bg-gray-100 w-[120px] sm:w-full h-[50px] sm:h-[50px] py-3 shadow-sm flex items-center pl-2
                   
                    `}
                    key={index}
                  >
                    <img src={item.icon} className="w-6 h-6 mr-2 rounded-none" />
                    <span className="text-[#1D3A70] text-[15px] font-[500]">{user?.user?.address?.country?.code}</span>
                  </div>
                ),
            ),
          ),
        )}
      </div>
    </div>
  )
}
export default InputCalculateMontantEnvoyer
