import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import localforage from 'localforage'
import Accueil from './icons/HomeIcon'
import Historique from './icons/HistoriqueIcon'
import Parrainage from './icons/ParrainageIcon'
import TransfertIcon from './icons/TransfertIcon'
import RechargeIcon from './icons/RechargeIcon'
import BeneficiareIcon from './icons/BeneficiareIcon'
import InviteFriends from '../assets/invite.svg'
import Settings from '../assets/setting.svg'
import SignOut from '../assets/signOut.svg'
import UserContext from 'contexts/UserContext'
import SignOutIcon from '../assets/SignOutIcon.svg'
import frameDashboard from '../assets/frameDashboard.svg'
import imageAccount from '../assets/imageAccount.svg'
import ModalLogOutContainer from 'containers/logOutContainer/modalLogOutContainer'
import AssuranceIcon from './icons/AssuranceIcon'
import PaimentFactureIcon from './icons/PaimentFactureIcon'
import TransfertCompteBancaireIcon from './icons/TransfertCompteBancaireIcon'
import BeneficiaryIcon from './icons/BeneficiaryIcon'
import AssuranceIconBlue from './icons/AssuranceIconBlue'

export default function SideBar() {
  const location = useLocation()
  const navigate = useNavigate()
  const { setUser } = useContext(UserContext)
  const[openModalLogOut, setOpenModalLogOut] = useState(false)

  // const [isLink, setIsLink] = useState(2)

  // useEffect(() => {
  //   if (location.state && location.state.isLink) {
  //     setIsLink(location.state.isLink)
  //   }
  // }, [location])

  // const navigateToSettingProfile = () => {
  //   navigate('/settingProfile', { state: isLink })
  //   console.log(isLink)
  // }

  const closeModal = () =>{
    setOpenModalLogOut(false)
  }

  const Links: {
    img: any
    secondImg: JSX.Element
    name: string
    link: string
  }[] = [
    {
      img: <Accueil fill="#8F8F8F" />,

      secondImg: <Accueil fill="#5754E5" />,
      name: 'Accueil',
      link: '/',
    },
    {
      img: <BeneficiareIcon fill="#8F8F8F" /> ,
      secondImg: <Historique fill="#5754E5" />,
      name: 'Historique',
      link: '/History',
    },

    {
      img: <RechargeIcon fill="#8F8F8F" />,
      secondImg: <RechargeIcon fill="#5754E5" />,
      name: 'Recharge Mobile',
      link: '/recharge',
    },
    {
      img: <TransfertIcon fill="#8F8F8F" />,
      secondImg: <TransfertIcon fill="#5754E5" />,
      name: "Transfert d'Argent",
      link: '/transfert/0',
    },

    {
      img: <TransfertCompteBancaireIcon fill="#8F8F8F" />,
      secondImg: <TransfertCompteBancaireIcon fill="#5754E5" />,
      name: "Transfert vers un compte bancaire",
      link: '/transfert/1',
    },
    // {
    //   img: <PaimentFactureIcon fill="#8F8F8F" />,
    //   secondImg: <PaimentFactureIcon fill="#5754E5" />,
    //   name: "Paiement de facture",
    //   link: '/paimentFacture',
    // },
    // {
    //   img: <AssuranceIcon fill="#8F8F8F" />,
    //   secondImg: <AssuranceIconBlue fill="#5754E5" />,
    //   name: "Assurance",
    //   link: '/assurance',
    // },
    {
      img: <BeneficiaryIcon fill="#8F8F8F" />,
      secondImg: <BeneficiaryIcon fill="#5754E5" />,
      name: 'Bénéficiaires',
      link: '/beneficiary',
    },
  ]
  return (
    <div className="pl-[43px]  lg:hidden w-[300px]">
      <div className=" flex flex-col gap-[34px]">
        {Links.map((item, index) => (
          <Link className=" flex gap-[16px] items-center " key={index} to={item.link}>
            <div>{location.pathname === item.link ? item.secondImg : item.img}</div>
            <div
              className={classNames(
                location.pathname === item.link ? 'text-[#5754E5]' : 'text-[#A5A5A5]',
                ' text-[17px] font-semibold',
              )}
            >
              {item.name}
            </div>
          </Link>
        ))}
      </div>

      <div className="mt-[2rem] mb-3 hover:cursor-pointer">
        <img className="" src={frameDashboard} onClick={()=>{navigate('/settingProfile/1')}}/>
      </div>

      <div
        className=" flex gap-[16px] items-center mt-[44px] hover:cursor-pointer"
         onClick={() => {setOpenModalLogOut(true)}}>
        <img src={SignOutIcon} alt="" />
        <div className="text-[#5754E5] text-[17px] font-semibold">Déconnexion</div>
      </div>
      <ModalLogOutContainer isOpen={openModalLogOut} closeModal={closeModal} openModalLogOut={openModalLogOut}/>
    </div>
  )
}

