import React, { useState } from 'react'
import classNames from 'classnames'
import imageSignIn from '../assets/imageSignIn.svg'
import imageLoginResponsive from '../assets/imageLoginResponsive.svg'
import Logo from '../assets/logo.png'
import ArrowRight from '../assets/ArrowRight.png'
import VisibleEye from '../assets/visibleEye.svg'
import InvisibleEye from '../assets/invisibleEye.svg'
import logoSignup from '../assets/logoSignup.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import ImageSignUp from './signUpContainer/imageSignUp'
import { resetPassword } from 'requests/user'
import ModalReinitialiserPassword from './modalReinitialiserPassword'
import newImageLogin from '../assets/newImageLogin.svg'

const MotDePassOublier: React.FC = () => {
  const [visible, setVisible] = useState(false)
  const [visibleConfirmed, setVisibleConfirmed] = useState(false)
  const [isInputClicked, setIsInputClicked] = useState(true)

  const[verifiePassword, setVerifiePassword] = useState(false)
  const[passwordSuccess, setPasswordSuccess] = useState(false)


  const openModalPassword=()=>{
    setVerifiePassword(true)
  }

  const closeModalPassword=()=>{
    setVerifiePassword(false)
  }

  const { token } = useParams()

  const navigate = useNavigate()
  const goBack = () => {
    navigate('/login')
  }

  const initialFormData = {
    newPassword: '',
    confirmedPassword: '',
  }

  const [formData, setFormData] = useState(initialFormData)

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required('Champs obligatoire')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[&,$,?,+!,#,%(),\-_<>=@[\]^|}{~]).{8,16}$/,
        'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.',
      ),

    confirmedPassword: yup
      .string()
      .required('Champs obligatoire')
      .oneOf([yup.ref('newPassword')], 'Les mots de passe ne correspondent pas')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[&,$,?,+!,#,%(),\-_<>=@[\]^|}{~]).{8,16}$/,
        'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.',
      ),
  })

  const handleSubmit = (values: typeof initialFormData) => {
    const credentials = {
      password: values.newPassword,
      confirmedPassword: values.confirmedPassword,
    }
    setFormData(initialFormData)

    if (formik.isValid) {
      resetPassword(credentials?.password, token).then(async (response: any) => {
        if (response?.success) {
          console.log(response)
          openModalPassword()
          setPasswordSuccess(true)
        }else{
          openModalPassword()
          setPasswordSuccess(false)
        }
      })
    } else {
      console.log('Nous sommes désolé une erreur est survenue. Merci de réessayer plus tard')
    }
  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <div>
      <div className="flex xs:flex-col sm:gap-y-[27px]">
        <div className="w-[50%] lg:w-[80%] sm:w-[358px] sm:mx-[16px] sm:h-[450px]">
          <div className="h-[70px] sm:h-0 xs:h-0">
            <div className="sm:hidden flex justify-between sm:mt-0 mt-[50px]">
              <img className="ml-[70px]" src={logoSignup} alt="" />
              <div className="hover:cursor-pointer flex gap-[21px] items-center justify-center mr-[36px] border-[2px] border-[#F1F4F9] rounded-xl w-[102px] h-[46px]">
                <img className=" w-[7.39px] h-[10px] " src={ArrowRight} onClick={goBack} />
                <div className=" text-[#5754E5] text-[15px]" onClick={goBack}>
                  Retour
                </div>
              </div>
            </div>
          </div>
          <div className="mx-[186px] sm:mx-[16px] xl:mx-[90px]">
            <div
              className={classNames(
                'sm:text-[20px] text-[24px] text-[#5754E5] 6xl:text-[18px] text-left sm:font-semibold font-bold mt-[80px] sm:mt-[0px]',
                isInputClicked && 'sm:mt-[15px]',
              )}
            >
              Réinitialisez votre mot de passe
            </div>
            <form
              className={classNames(
                'grid grid-cols-1 sm:gap-[15px] gap-[30px] sm:mt-[30px]) ',
                isInputClicked && 'mt-5',
              )}
              onSubmit={formik.handleSubmit}
            >
              <div>
                <div className="relative h-[130px]">
                  <input
                    className="bg-[#F9FAFB] px-[14px] py-4 rounded-xl w-full mt-4 placeholder:text-[#9CA3AF] text-[#5754E5]"
                    name="newPassword"
                    placeholder="Nouveau mot de passe"
                    type={visible ? 'text' : 'password'}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur('newPassword')}
                    autoComplete='off'
                  />
                  <img
                    src={InvisibleEye}
                    alt=""
                    className={classNames('absolute', !visible ? 'top-[30%] left-[90%] w-6 h-6' : 'hidden')}
                    onClick={() => setVisible(!visible)}
                  />
                  <img
                    src={VisibleEye}
                    alt=""
                    className={classNames('absolute', visible ? 'top-[30%] left-[90%] w-6 h-6' : 'hidden')}
                    onClick={() => setVisible(!visible)}
                  />
                  {formik.errors.newPassword && formik.touched.newPassword && (
                    <div className="text-red-500 text-sm">{formik.errors.newPassword}</div>
                  )}
                </div>
                <div className="text-[13px] text-[#6B7280] mt-1">
                  Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère
                  spécial.
                </div>
                <div className="relative h-[120px]">
                  <input
                    className="bg-[#F9FAFB] px-[14px] py-4 rounded-xl w-full mt-4 placeholder:text-[#9CA3AF] text-[#5754E5]"
                    name="confirmedPassword"
                    placeholder="Confirmer le nouveau mot de passe"
                    type={visibleConfirmed ? 'text' : 'password'}
                    value={formik.values.confirmedPassword}
                    onChange={formik.handleChange}
                    autoComplete='off'
                    onBlur={formik.handleBlur('confirmedPassword')}
                  />
                  <img
                    src={InvisibleEye}
                    alt=""
                    className={classNames('absolute', !visibleConfirmed ? 'top-[30%] left-[90%] w-6 h-6' : 'hidden')}
                    onClick={() => setVisibleConfirmed(!visibleConfirmed)}
                  />
                  <img
                    src={VisibleEye}
                    alt=""
                    className={classNames('absolute', visibleConfirmed ? 'top-[30%] left-[90%] w-6 h-6' : 'hidden')}
                    onClick={() => setVisibleConfirmed(!visibleConfirmed)}
                  />
                  {formik.errors.confirmedPassword && formik.touched.confirmedPassword && (
                    <div className="text-red-500 text-sm">{formik.errors.confirmedPassword}</div>
                  )}
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <button
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                  className={` text-white px-[14px] py-4 text-[15px] font-medium rounded-xl w-[80%]
                ${formik.isSubmitting || !formik.isValid ? 'bg-gray-500' : 'bg-[#5754E5]'}
                `}
                >
                  Réinitialiser
                </button>
              </div>
            </form>
          </div>
          <ModalReinitialiserPassword isOpen={verifiePassword} closeModal={closeModalPassword} passwordSuccess={passwordSuccess}/>
        </div>
        <img
          className={classNames(
            isInputClicked
              ? ' hidden sm:flex w-[50%] sm:w-[330px] sm:mt-[30px] sm:mx-[30px] sm:order-first '
              : 'hidden',
          )}
          src={imageLoginResponsive}
          alt=""
        />
        <ImageSignUp image={newImageLogin} />
      </div>
    </div>
  )
}
export default MotDePassOublier
