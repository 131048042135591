import classNames from 'classnames'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import iconVisa from '../../assets/iconVisa.svg'
import verifiedbyvisamastercard from '../../assets/verifiedbyvisamastercard.svg'
import verifiedbyvisa from '../../assets/verifiedbyvisa.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaiementForm from './paiementForm'

interface Props {
  isTopnet: boolean
  isSoned: boolean
  isSteg: boolean
  onChangeVal: (value3: string, value2: string) => void
  ProviderCode: any
  setNumCarte: any
  setCodeCVC: any
  setMonth: any
  setYear: any
  onChangeStep: any
  onBackChangeStep: any
  paiementFactureUser: any
  functionTracks:any
  handleConfirmPayment:any
  clientSecret:string
}

const Paiement: React.FC<Props> = ({
  isTopnet,
  isSoned,
  isSteg,
  onChangeVal,
  ProviderCode,
  setNumCarte,
  setCodeCVC,
  setMonth,
  setYear,
  onChangeStep,
  onBackChangeStep,
  paiementFactureUser,
  functionTracks,
  handleConfirmPayment,
  clientSecret
}) => {

  const stripePromise = loadStripe(
    'pk_test_51OzdEkP1vfmwjObRWZs9jeUgtjVr5yPKXnOEtrKZbJdd7boKLUA7KP6VVEmXkqJJegtrebeTm3z9plymQavS3ZwL009Vrh8Udc',
  )

  return (
    <div className="sm:mt-5 xs:mt-5 5xl:flex 5xl:justify-center xs:flex xs:justify-center">
      <Elements stripe={stripePromise}> 
          <PaiementForm 
            handleConfirmPayment={handleConfirmPayment} 
            functionTracks={functionTracks}
            ProviderCode={ProviderCode}
            isTopnet={isTopnet}
            isSteg={isSteg}
            isSoned={isSoned}
            clientSecret={clientSecret}
          /> 
        </Elements> 
    </div>
  )
}
export default Paiement
