import React, { useState } from 'react'
import iconPrint from '../../assets/iconPrint.svg'

interface Props {
  invoicesData: any
}

const HistoryTablePaiement: React.FC<Props> = ({ invoicesData }) => {

  const [status, setStatus] = useState<string>('Complete')
  const [statusCanceled, setStatusCanceled] = useState<string>('canceled')
  const [statusInProgress, setStatusInProgress] = useState<string>('in progress')

  function formatDate(originalDate: any) {
    const dateObj = new Date(originalDate)
    const jour = dateObj.getDate()
    const mois = dateObj.getMonth() + 1
    const annee = dateObj.getFullYear()
    const jourFormatte = jour < 10 ? `0${jour}` : jour
    const moisFormatte = mois < 10 ? `0${mois}` : mois
    return `${jourFormatte}/${moisFormatte}/${annee}`
  }

  const [clickedRows, setClickedRows] = useState<boolean[]>([])

  const handleInputClick = (index: number) => {
    const newClickedRows = [...clickedRows]
    newClickedRows[index] = !newClickedRows[index]
    setClickedRows(newClickedRows)
  }

  return (
    <div className="mx-auto mt-[4rem] mr-[5rem] sm:mr-0 lg:mr-1">
      <div className="table-container">
        <table className="table-auto w-full flex1 text-left">
          <thead>
            <tr>
              <th className="border-b px-4 py-2 sm:border-none ">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393]">Référence</div>
              </th>
              
              <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393] text-left">Facteurie</div>
              </th>

              <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393] text-left">Montant en dinar</div>
              </th>

              <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393] text-left">Montant en euro</div>
              </th>

              <th className="border-b px-4 py-2 sm:border-none ">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393]">Date</div>
              </th>
              <th className="border-b px-4 py-2 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[13px] text-[#939393] text-center">Statut</div>
              </th>
              
            </tr>
          </thead>
          {invoicesData &&
            invoicesData.map((listInvoices: any, index: number) => (
              <tbody key={index}>
                <tr className='hover:bg-gray-100'>
                  <td
                    className={`border-b px-4 lg:px-2 py-2`}
                    onClick={() => handleInputClick(index)}
                  >
                    <div className="text-[#22242C] text-[14px] sm:text-[11px] lg:text-[13px] font-semibold">
                      {listInvoices?.reference}
                    </div>
                  </td>

                  <td
                    className={`border-b px-4 lg:px-2 py-2`}
                    onClick={() => handleInputClick(index)}
                  >
                    <div className="text-[#22242C] text-[14px] sm:text-[11px] lg:text-[13px] font-semibold">
                      {listInvoices?.billerCode === 's600' ? 'Topnet' : listInvoices?.billerCode === 's701' ? 'Steg' : listInvoices?.billerCode === 's502' ? 'Sonede' : ''}
                    </div>
                  </td>

                  <td className="border-b px-4 py-2 ">
                    <div className="text-[#22242C] text-[14px] lg:text-[13px] font-semibold">
                      {listInvoices?.amount?.amountTorecieve} TND
                    </div>
                  </td>

                  <td className="border-b px-4 py-2 ">
                    <div className="text-[#22242C] text-[14px] lg:text-[13px] font-semibold">
                      {listInvoices?.amount?.amountToPay} EUR
                    </div>
                  </td>

                  <td className="border-b px-4 py-2 ">
                    <div className="text-[#22242C] text-[14px] lg:text-[13px] font-semibold">
                      {formatDate(listInvoices?.createdAt)}
                    </div>
                  </td>

                  <td className="border-b">
                    {status === listInvoices?.status ? (
                      <div className="bg-[#EBFFF3] flex items-center text-[14px] justify-center w-auto p-2 sm:p-[5px] rounded-lg">
                        <span className="text-[#20C374] ml-1">Terminé</span>
                      </div>
                    ) : statusInProgress === listInvoices?.status ? (
                      <div className="bg-[#FFDECC] flex items-center justify-center w-auto p-2 h-[35px] 2md:h-[22px] text-[14px] rounded-lg">
                        <span className="text-[#FF5C00]">En cours</span>
                      </div>
                    ) : statusCanceled === listInvoices?.status ? (
                      <div className="bg-[#FED7D8] flex items-center justify-center w-auto p-2 h-[35px] 2md:h-[22px] text-[14px] rounded-lg">
                        <span className="text-[#EC1C24]">Annulé</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      </div>
    </div>
  )
}

export default HistoryTablePaiement
