import axios from "helpers/axios"


export const userFacture = (body: any) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/invoices/userInvoices', body, {
          headers: {
            accept: 'application/json',
          },
        })
        .then(response => {
          resolve(response?.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  export const payedFacture = (body: any) =>
  axios
    .post(`/invoices/payInvoice`, body, {
      headers: {
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})


export const invoiceSherlockSecure = (token:string | undefined, body: any) =>
  axios
    .post(`/invoices/sherlockSecure`, body, {
      headers: {
        Authorization:token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})


export const confirmPayment = (invoice_id: string| undefined) =>
  axios
    .post(`/invoices/confirmPayment`,{invoice_id}, {
      headers: {
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})

export const historyinvoices = (userId: string | undefined, token: string | undefined) =>
  axios
    .get(`/invoices/${userId}`, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})

export const PaymentConfirmed = (body: any | undefined, token: string | undefined) =>

axios
  .post(`/recharges/confirmPayment`, body ,{
    headers: {
      Authorization: token,
      accept: 'application/json',
    },
  })
  .then((response) => {
    return response?.data
  })
  .catch((error) => {
    console.log(error)
  })