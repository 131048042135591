import React, { useContext, useState } from 'react'
import ImageSignUp from './signUpContainer/imageSignUp'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import imageSignIn from '../assets/imageSignIn.svg'
import imageLoginResponsive from '../assets/imageLoginResponsive.svg'
import ArrowRight from '../assets/ArrowRight.png'
import logoSignup from '../assets/logoSignup.svg'
import UserContext from 'contexts/UserContext'
import ModalEmailContainer from './modalEmailContainer'
import { useFormik } from 'formik'
import * as yup from 'yup'
import imageLoginMobile from '../assets/imageLoginMobile.svg'
import imageLoginWeb from '../assets/imageLoginWeb.svg'
import { verificationEmail } from 'requests/user'
import newImageLogin from '../assets/newImageLogin.svg'

const EmailVerificationContainer = () => {
  const { user } = useContext(UserContext)
  const [isInputClicked, setIsInputClicked] = useState(true)
  const [isopenModalEmail, setIsOpenModalEmail] = useState(false)
  const [emailSuccess, setEmailSuccess] = useState(false)

  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  const openModalEmail = () => {
    setIsOpenModalEmail(true)
  }

  const closeModalEmail = () => {
    setIsOpenModalEmail(false)
  }

  const initialFormData = {
    email: '',
  }

  const [formData, setFormData] = useState(initialFormData)

  const validationSchema = yup.object({
    email: yup.string().required('Email obligatoire'),
  })

  const handleSubmit = async (values: typeof initialFormData) => {
    const credentials = {
      email: values.email,
    }
    setFormData(initialFormData)

    if (formik.isValid) {
      verificationEmail(credentials?.email).then(async (response: any) => {
        if (response?.success === true) {
          console.log('response', response)
          openModalEmail()
          setEmailSuccess(true)
        } else {
          openModalEmail()
          setEmailSuccess(false)
        }
      })
    }
  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <div>
      <div className="flex xs:flex-col sm:gap-y-[27px]">
        <div className="w-[50%] lg:w-[80%] sm:w-[358px] sm:mx-[16px] sm:h-[450px]">
          <div className="h-[70px] sm:h-0 xs:h-0">
            <div className="sm:hidden flex justify-between sm:mt-0 mt-[50px]">
              <img className="ml-[70px]" src={logoSignup} alt="" />
              <div className="hover:cursor-pointer flex gap-[21px] items-center justify-center mr-[36px] border-[2px] border-[#F1F4F9] rounded-xl w-[102px] h-[46px]">
                <img className=" w-[7.39px] h-[10px] " src={ArrowRight} onClick={goBack} />
                <div className=" text-[#5754E5] text-[15px]" onClick={goBack}>
                  Retour
                </div>
              </div>
            </div>
          </div>
          <div className="mx-[186px] sm:mx-[16px] xl:mx-[90px]">
            <div
              className={classNames(
                'sm:text-[20px] text-[24px] text-[#5754E5] 6xl:text-[18px] text-left sm:font-semibold font-bold mt-[80px] sm:mt-[0px]',
                isInputClicked && 'sm:mt-[15px]',
              )}
            >
              Verification email
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className={classNames(
                'grid grid-cols-1 sm:gap-[15px] gap-[30px] sm:mt-[30px]) ',
                isInputClicked && 'mt-5',
              )}
            >
              <div className="h-[90px]">
                <input
                  className="bg-[#F9FAFB] px-[14px] py-4 rounded-xl w-full mt-4 placeholder:text-[#9CA3AF] text-[#5754E5]"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur('email')}
                  placeholder="Email"
                  autoComplete='off'
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="text-red-500 text-[14px] sm:text-[12px]">{formik.errors.email}</div>
                )}
              </div>
              <div className="flex justify-center mt-5">
                <button
                  type="submit"
                  className={` text-white px-[14px] py-4 text-[15px] font-medium rounded-xl w-[80%] bg-[#5754E5]`}
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
          <ModalEmailContainer isOpen={isopenModalEmail} closeModal={closeModalEmail} emailSuccess={emailSuccess}/>
        </div>
        <img
          className={classNames(
            isInputClicked
              ? ' hidden sm:flex w-[50%] sm:w-[330px] sm:mt-[30px] sm:mx-[30px] sm:order-first '
              : 'hidden',
          )}
          src={imageLoginMobile}
          alt=""
        />
        <ImageSignUp image={newImageLogin} />
      </div>
    </div>
  )
}
export default EmailVerificationContainer
