import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import topnetLogo from '../../assets/topnetLogo.svg'
import sonedeLogo from '../../assets/sonedeLogo.svg'
import stegLogo from '../../assets/stegLogo.svg'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import { Spinner } from 'utils/loading'

interface Props {
  isTopnet: boolean
  isSoned: boolean
  isSteg: boolean
  onChangeVal: (value3: string, value2: string) => void
  onBackChangeStep: any
  onChangeStep: any
  setMontantDinar: (value: string) => void
  setMontantEuro: (value: string) => void
  facture: any
  billerCode: any
  setBillerCode: (value: string) => void
  idFacture: any
  setIdFacture: (value: string) => void
  failedPaiementFacture: boolean
  legnth: any
  functionTracks: any
  handleConfirmPayment: any
  setInvoiceId: (value: string) => void
  paiementFacture: any
}

const FacturePaid: React.FC<Props> = ({
  isTopnet,
  isSoned,
  isSteg,
  onChangeVal,
  onBackChangeStep,
  onChangeStep,
  setMontantDinar,
  setMontantEuro,
  facture,
  billerCode,
  setBillerCode,
  idFacture,
  setIdFacture,
  failedPaiementFacture,
  legnth,
  functionTracks,
  handleConfirmPayment,
  setInvoiceId,
  paiementFacture,
}) => {
  const [ON, setON] = useState(false)
  const [val, setVal] = useState<any>()
  const [indexRecharge, setIndexRecharge] = useState(-1)

  const [loading, setLoading] = useState(true)

  const getCard = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }

  useEffect(() => {
    getCard()
  }, [])

  return (
    <div>
      <div className="2sm:mx-auto 2sm:overflow-y-auto no-scrollbar 2sm:max-h-[250px] sm:max-h-[340px] 4xl:grid 4xl:grid-cols-2 5xl:mt-0 5xl:grid-cols-2 3xl:grid-cols-2 2xl:grid-cols-2 sm:grid-cols-1 gap-[9px] sm:gap-2 xs:gap-2 sm:mt-8 xs:mt-8">
        {failedPaiementFacture ? (
          <div className="relative mt-[8rem] mb-[2rem]">
            <div className="absolute left-0 bottom-0 w-[520px] sm:w-auto border-2 border-dashed border-[#5754E5] text-[20px] text-center text-[#6B6B6B] rounded-2xl p-3">
              Vous n’avez pas de facture à payer
            </div>
          </div>
        ) : loading ? (
          <div className="mt-5 ml-[14rem] sm:ml-[5rem]">
            <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
          </div>
        ) : (
          <>
            {facture?.invoicesList?.map((item: any, index: number) => (
              <div key={index}>
                {item.billerCode === 's600' ? (
                  <div
                    className={classNames(isTopnet ? '' : 'hidden')}
                    onClick={() => {
                      setMontantDinar(item?.AmountTND)
                      setMontantEuro(item?.amountToPay)
                      setBillerCode(item?.billerCode)
                      setIdFacture(item?.id)
                    }}
                  >
                    <div className="flex flex-col items-center">
                      <div
                        className="flex flex-col bg-[#4382C5] px-5 py-3 rounded-2xl w-fill-available hover:cursor-pointer"
                        onClick={() => {
                          setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                          setInvoiceId(item?.id)
                          setON(true)
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <img src={topnetLogo} />
                          <div className="relative items-center">
                            <img src={RadioFocus} className="w-6 h-6" />
                            <img
                              src={RadioON}
                              className={classNames(
                                ON && indexRecharge === index ? 'absolute inset-[25%] w-3 h-3' : 'hidden',
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="relative mt-5">
                            <span className="text-white text-[20px] font-semibold">{item?.AmountTND} TND</span>
                          </div>

                          <div className="relative mt-5 mr-[25px]">
                            <span className="text-white font-semibold text-[19px]">{item?.amountToPay} €</span>
                            <span className="text-white absolute bottom-[2rem] left-0 pr-2 font-normal text-[12px] whitespace-nowrap">
                              Montant à payer
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {item.billerCode === 's502' ? (
                  <div className={classNames(isSoned ? '' : 'hidden')}>
                    <div className="flex flex-col items-center">
                      <div
                        className="flex flex-col bg-[#2194C4] px-5 py-3 rounded-2xl w-fill-available hover:cursor-pointer"
                        onClick={() => {
                          setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                          setON(true)
                          setIdFacture(item?.id)
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <img src={sonedeLogo} />
                          <div className="relative items-center">
                            <img src={RadioFocus} className="w-6 h-6" />
                            <img
                              src={RadioON}
                              className={classNames(
                                ON && indexRecharge === index ? 'absolute inset-[25%] w-3 h-3' : 'hidden',
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="relative mt-5">
                            <span className="text-white text-[20px] font-normal">{item.AmountTND}</span>
                            <span className="text-white absolute bottom-[1rem] font-bold text-[18px]">TND</span>
                          </div>

                          <div className="relative mt-5 mr-[25px]">
                            <span className="text-white font-bold text-[19px]">{item.amountToPay}</span>
                            <span className="text-white absolute bottom-[1rem] font-bold text-[18px]">€</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {item.billerCode === 's701' ? (
                  <div className={classNames(isSteg ? '' : 'hidden')}>
                    <div className="flex flex-col items-center">
                      <div
                        className="flex flex-col bg-[#000000] px-5 py-3 rounded-2xl w-fill-available hover:cursor-pointer"
                        onClick={() => {
                          setVal(indexRecharge !== index ? setIndexRecharge(index) : setIndexRecharge(-1))
                          setON(true)
                          setIdFacture(item?.id)
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <img src={sonedeLogo} />
                          <div className="relative items-center">
                            <img src={RadioFocus} className="w-6 h-6" />
                            <img
                              src={RadioON}
                              className={classNames(
                                ON && indexRecharge === index ? 'absolute inset-[25%] w-3 h-3' : 'hidden',
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="relative mt-5">
                            <span className="text-[#EB3124] text-[20px] font-normal">{item.AmountTND}</span>
                            <span className="text-[#EB3124] absolute bottom-[1rem] font-bold text-[18px]">TND</span>
                          </div>

                          <div className="relative mt-5 mr-[25px]">
                            <span className="text-white font-bold text-[19px]">{item.amountToPay}</span>
                            <span className="text-white absolute bottom-[1rem] font-bold text-[18px]">€</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <div className={`flex gap-[16px] justify-center sm:justify-normal my-5 place-self-center sm:w-full`}>
        <button
          className={classNames(
            'text-[18px] text-[#556987] p-2 h-[56px] w-[180px] border border-[#556987] font-sans rounded-xl',
          )}
          onClick={onBackChangeStep}
        >
          Précédent
        </button>
        <button
          className={classNames(
            'text-[18px] text-[#FFF] w-[357px] p-2 h-[56px] rounded-xl  sm:w-full bg-[#4743C9]',
            // indexRecharge === -1 || idFacture === '' ? 'bg-gray-400' : 'bg-[#4743C9] ',
          )}
          onClick={() => {
            paiementFacture()
            onChangeStep()
            functionTracks('2')
          }}
          // disabled={indexRecharge === -1 || idFacture === ''}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}

export default FacturePaid
